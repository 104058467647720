import React from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import {Layout} from "./layout/Layout";
import {MainPage} from "./main/MainPage";

function App() {

  return (
    <div>
      <Layout>
        <Routes>
          <Route path={'/'} element={<MainPage />}/>
          <Route path={'*'} element={<MainPage />}/>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;