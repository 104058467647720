import React from 'react';
import preview from './preview.jpg';
import styles from './MainPage.module.css';

export const MainPage = () => {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img
          src={preview}
          className={styles.preview}
          alt={'warning'}
        />
        <p>Сайт в процессе разработки</p>
      </div>
    </div>
  );
};